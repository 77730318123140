import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import WorkDetail from "../../components/workdetail"
import playarVideo from "../../assets/playar.mp4"

import ReactPlayer from "react-player"

import { playerWrapper169, reactPlayer } from "./playar.module.css"

const query = graphql`
  {
    allFile(filter: { name: { eq: "Playar" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`

const Playar = () => {
  const data = useStaticQuery(query)
  const playarImage = data.allFile.nodes[0].childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title="Playar Studio" />
      <WorkDetail
        company="Playar Studio"
        image={playarImage}
        description="Concept creation and (motion) design for several projects in collaboration with Playar Studio."
        brief="Playar studio is a company that is specialized in creating Augmented Reality experiences for brands. As a working student I collaborated with them on a project for the new series of Streamz ‘F*ck You Very Very Much’. They asked us to create a mood filter based on the quotes from the series."
        solution="I came up with several concepts from which a few were chosen. Then I used my graphic and motion design skills to further develop these ideas into practice."
      />
      <div className={playerWrapper169}>
        <ReactPlayer
          url={playarVideo}
          controls={true}
          width="100%"
          height="100%"
          pip={true}
          className={reactPlayer}
        />
      </div>
    </Layout>
  )
}

export default Playar
