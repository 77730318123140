import React from "react"
import WorkUnitOverview from "./workUnitOverview"

import { Link } from "gatsby"

import { line, back, link, backContainer } from "./workdetail.module.css"

const WorkDetail = ({ company, image, description, brief, solution }) => {
  return (
    <section className="container">
      <Link to="/work" className={link}>
        <div className={backContainer}>
          <hr className={line} />
          <p className={back}>return to overview</p>
        </div>
      </Link>
      <WorkUnitOverview
        company={company}
        image={image}
        description={description}
        page="workdetail"
      />
      <br />
      <br />
      <h3 className="subtitle">The brief.</h3>
      <p className="description">{brief}</p>
      <h3 className="subtitle">The solution.</h3>
      <p className="description">{solution}</p>
    </section>
  )
}

export default WorkDetail
